import { useRef, useState } from "react";
import "./Road_Map.css";
import useSmoothHorizontalScroll from "use-smooth-horizontal-scroll";

function Road_Map() {
  const { scrollContainerRef, handleScroll, scrollTo, isAtStart, isAtEnd } = useSmoothHorizontalScroll();
  return (
    <div id="roadmap">
      <section className="timeline subc-area section-divide rdmp" id="roadmap">
        <div className="row">
          <div className="col-md-12">
            <div className="section-title w-90 m-l-5 text-center">
              <h2>Roadmap</h2>
              {/* <p>
                The use of cryptocurrencies has become more widespread, The
                origin platform idea.
              </p> */}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <ol ref={scrollContainerRef} onScroll={handleScroll} style={{ overflowX: "hidden" }}>
              <li>
                <div>
                  <time>2023(Q3)</time>
                  - Idea Conception <br />
                  - Strategic Planning <br />
                  - Token Generation and Event Planning <br />
                  - Comprehensive Market Research <br />
                  - Tokenomics Planning
                </div>
              </li>
              <li>
                <div>
                  <time>2023(Q4)</time>
                  - Building a Skilled Development Team <br />
                  - Designing the Platform Architecture <br />
                  - Planning the Development of XRT Modules <br />
                  - XRT Development and Design <br />
                  - Listing on centralized exchange 
                </div>
              </li>
              <li>
                <div>
                  <time>2024(Q1)</time>
                  - Cultivating an Engaged Online Community <br />
                  - 1st DAO - OTT platform launching <br />
                  - Fostering Community Engagement in DAO <br />
                  - Securing Initial Funding <br />
                  - Amplifying Brand Presence through Social Media 
                </div>
              </li>
              <li>
                <div>
                  <time>2024(Q3)</time>
                  - 2nd DAO - forex broker house launch <br />
                  - Expansion of first DAO with its entertaining projects<br />
                  - Community involvement enhancement in DAO
                </div>
              </li>
              <li>
                <div>
                  <time>2025(Q1)</time> 
                  - Facilitating DAO with multiple projects.<br />
                  - Driving International Mass Adoption of XRT.<br />
                  - Implementing Global Operations and Advanced Sales Strategies<br />
                  - 3rd DAO - Decentralised crypto bank launching
                </div>
              </li>
              {/* <li>
                <div>
                  <time>2024(Q4)</time> - Multi-Exchange Listing
                  <br />
                  - Price Tracking Website Listing
                  <br />
                  - Diversifying Use Cases for Other Platforms
                  <br />- Comprehensive Marketing Campaign
                </div>
              </li> */}
              {/* <li>
                <div>
                  <time>2025(Q1)</time> - Facilitating Cross-Blockchain
                  Transactions
                  <br />
                  - Driving International Mass Adoption of Sunshinecoin
                  <br />- Implementing Global Operations and Advanced Sales
                  Strategies
                </div>
              </li> */}
              <li />
            </ol>
            <div className="arrows">
              <button className="arrow arrow__prev disabled" onClick={() => scrollTo(-100)} disabled={isAtStart}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ fill: "#1b9ce9" }}
                  width={40}
                  height={40}
                  viewBox="0 0 24 24"
                >
                  <path d="M0 12c0 6.627 5.373 12 12 12s12-5.373 12-12-5.373-12-12-12-12 5.373-12 12zm7.58 0l5.988-5.995 1.414 1.416-4.574 4.579 4.574 4.59-1.414 1.416-5.988-6.006z" />
                </svg>
              </button>
              <button className="arrow arrow__next" onClick={() => scrollTo(100)} disabled={isAtEnd}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ fill: "#1b9ce9" }}
                  width={40}
                  height={40}
                  viewBox="0 0 24 24"
                >
                  <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.218 19l-1.782-1.75 5.25-5.25-5.25-5.25 1.782-1.75 6.968 7-6.968 7z" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Road_Map;
