import React from "react";
import { HiOutlineChevronDoubleDown } from "react-icons/hi";

import "./Faq.css";
import faq from "../Assets/faq.png";

function Home() {
  return (
    <>
      <div className="faqbg py-5" id="faq">
        <div className="container ">
          <div className="row">
            <div className="col-md-9 col-lg-7">
              <div
                className="section-heading wow fadeInUp"
                data-wow-delay="400ms"
                style={{
                  visibility: "visible",
                  animationDelay: "400ms",
                  animationName: "fadeInUp",
                }}
              >
                <h4>FAQ</h4>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="faq_img_set">
                <img src={faq} alt="" />
              </div>
            </div>
            <div className="col-12 col-md-6 ">
              <div
                className="accordion accordion-flush  "
                id="accordionFlushExample"
              >
                {/* <i className="fa-solid fa-angles-down"></i> */}
                <div className="accordion-item mb-3">
                  <h2 className="accordion-header" id="flush-headingOne">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOne"
                      aria-expanded="false"
                      aria-controls="flush-collapseOne"
                    >
                      What is XR?
                      {/* <HiOutlineChevronDoubleDown /> */}
                    </button>
                  </h2>
                  <div
                    id="flush-collapseOne"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingOne"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      XR is a centralized cryptocurrency exchange that allows
                      users to trade a wide range of cryptocurrencies in a
                      secure and user-friendly environment.
                    </div>
                  </div>
                </div>

                {/* <div className="accordion-item mb-3">
                  <h2 className="accordion-header" id="flush-headingTwo">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseTwo"
                      aria-expanded="false"
                      aria-controls="flush-collapseTwo"
                    >
                      How do I create an account on XR?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingTwo"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      To create an account on XR, simply visit our website and
                      click on the "Sign Up" button. Follow the registration
                      process, provide the required information, and complete
                      the account verification steps.
                    </div>
                  </div>
                </div> */}
                <div className="accordion-item mb-3">
                  <h2 className="accordion-header" id="flush-headingThree">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseThree"
                      aria-expanded="false"
                      aria-controls="flush-collapseThree"
                    >
                      What cryptocurrencies are supported on XR?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingThree"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      XR supports a diverse selection of cryptocurrencies,
                      including popular ones like Bitcoin (BTC), Ethereum (ETH),
                      and many other altcoins.{" "}
                    </div>
                  </div>
                </div>
                <div className="accordion-item mb-3">
                  <h2 className="accordion-header" id="flush-headingFour">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseFour"
                      aria-expanded="false"
                      aria-controls="flush-collapseFour"
                    >
                      Is XR secure?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseFour"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingFour"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      Yes, XR employs robust security measures to ensure the
                      safety of user funds and personal information. We utilize
                      encryption, two-factor authentication (2FA), and cold
                      storage to safeguard assets.
                    </div>
                  </div>
                </div>
                <div className="accordion-item mb-3">
                  <h2 className="accordion-header" id="flush-headingF">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseF"
                      aria-expanded="false"
                      aria-controls="flush-collapseF"
                    >
                      How can I deposit funds to my XR account?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseF"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingF"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      To deposit funds, log in to your XR account and navigate
                      to the "Deposit" section. Select the cryptocurrency you
                      wish to deposit, generate a deposit address, and transfer
                      funds from your external wallet to the provided address.
                    </div>
                  </div>
                </div>
                <div className="accordion-item mb-3">
                  <h2 className="accordion-header" id="flush-headingSix">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseSix"
                      aria-expanded="false"
                      aria-controls="flush-collapseSix"
                    >
                      What trading options does XR offer?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseSix"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingSix"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      XR offers various trading options, including market
                      orders, limit orders, and stop-loss orders, allowing you
                      to execute trades according to your preferences.
                    </div>
                  </div>
                </div>
                <div className="accordion-item mb-3">
                  <h2 className="accordion-header" id="flush-headingseven">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseseven"
                      aria-expanded="false"
                      aria-controls="flush-collapseseven"
                    >
                      Are there any trading fees on XR?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseseven"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingseven"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      Yes, XR charges trading fees on executed trades. The fee
                      structure can be found on our website and may vary
                      depending on the trading volume and other factors.
                    </div>
                  </div>
                </div>
                <div className="accordion-item mb-3">
                  <h2 className="accordion-header" id="flush-headingNo">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseNo"
                      aria-expanded="false"
                      aria-controls="flush-collapseNo"
                    >
                      Is there a mobile app for XR?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseNo"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingNo"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      Yes, XR offers a mobile app for both Android and iOS
                      devices, allowing you to trade cryptocurrencies on the go.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingNone">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseNine"
                      aria-expanded="false"
                      aria-controls="flush-collapseNine"
                    >
                      How can I contact XR support?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseNine"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingNine"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      For any inquiries or assistance, you can contact XR
                      support through our website's "Support" or "Contact Us"
                      section. Our customer support team will respond to your
                      queries promptly.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
