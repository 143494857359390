import React from "react";

export default function Wellcome_page() {
  return (
    <div>
      <div className="uniq-area section-padding-100-50">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-md-6 mb-50 wow fadeInUp"
              data-wow-delay="400ms"
              style={{
                visibility: "visible",
                animationDelay: "400ms",
                animationName: "fadeInUp",
              }}
            >
              {/* <!-- Thumb area --> */}
              <div className="uniq-content-thumb-1">
                <img src="assets/img/1.png" alt="" />
              </div>
              {/* <div className="uniq-follow-us">
                    <div className="uniq-follow text-center">
                        <h4>Follow us</h4>
                        <a href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a>
                        <a href="#"><i className="fa fa-twitter" aria-hidden="true"></i></a>
                        <a href="#"><i className="fa fa-instagram" aria-hidden="true"></i></a>
                        <a href="#"><i className="fa fa-dribbble" aria-hidden="true"></i></a>
                    </div>
                    </div>  */}
            </div>
            <div
              className="col-md-6 mb-50 wow fadeInUp"
              data-wow-delay="400ms"
              style={{
                visibility: "visible",
                animationDelay: "400ms",
                animationName: "fadeInUp",
              }}
            >
              <div className="uniq-content-text">
                <div
                  className="section-heading wow fadeInUp"
                  data-wow-delay="400ms"
                  style={{
                    visibility: "visible",
                    animationDelay: "400ms",
                    animationName: "fadeInUp",
                  }}
                >
                  <h6>Welcome</h6>
                  <h4></h4>
                  <p>
                    Redefining digital money with safe, decentralized solutions.
                    Join us on a journey of financial innovation!
                  </p>
                  <br />
                  <p>
                    XR is a dynamic and user-centric centralized cryptocurrency
                    exchange designed to empower traders with a seamless and
                    secure platform for crypto transactions.
                  </p>
                  
                  <div className="contract_link">
                    <h4>Smart Contract</h4>
                    <p><a href="https://bscscan.com/token/0xEF86A451AF7158245bd4fa6b1F8C8Aa297E42B7d" target="_blank">
                    0xEF86A451AF7158245bd4fa6b1F8C8Aa297E42B7d
                    </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="feature-area section-padding-100-50" id="feature">
        <div className="container">
          <div className="row">
            <div className="col-md-9 col-lg-7">
              <div
                className="section-heading wow fadeInUp"
                data-wow-delay="400ms"
                style={{
                  visibility: "visible",
                  animationDelay: "400ms",
                  animationName: "fadeInUp",
                }}
              >
                <h4>Benefits</h4>
                <p>
                  “XR Token's Innovative Technologies Protect Users and Empower
                  Investors”
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            {/* <!-- Single Feature Area --> */}
            <div className="col-md-6 col-lg-3">
              <div
                className="single-feature-area text-center wow fadeInUp"
                data-wow-delay="800ms"
                style={{
                  visibility: "visible",
                  animationDelay: "400ms",
                  animationName: "fadeInUp",
                }}
              >
                {/* <!-- icon area --> */}
                <div className="icon-area">
                  <i className="fa fa-television" aria-hidden="true"></i>
                  <span className="dots">
                    <i className="dot dot1"></i>
                    <i className="dot dot2"></i>
                    <i className="dot dot3"></i>
                  </span>
                </div>
                <h5>Decentralization</h5>
                <p>
                  With XR Token, users experience secure and transparent
                  transactions in a decentralized environment.
                </p>
              </div>
            </div>
            {/* <!-- Single Feature Area --> */}
            <div className="col-md-6 col-lg-3">
              <div
                className="single-feature-area text-center wow fadeInUp"
                data-wow-delay="800ms"
                style={{
                  visibility: "visible",
                  animationDelay: "400ms",
                  animationName: "fadeInUp",
                }}
              >
                {/* <!-- icon area --> */}
                <div className="icon-area">
                  <i className="fa fa-tablet" aria-hidden="true"></i>
                  <span className="dots">
                    <i className="dot dot1"></i>
                    <i className="dot dot2"></i>
                    <i className="dot dot3"></i>
                  </span>
                </div>
                <h5>Accessibility</h5>
                <p>
                  With XR Token, anyone can participate in the world of
                  cryptocurrencies, fostering global accessibility.
                </p>
              </div>
            </div>
            {/* <!-- Single Feature Area --> */}
            <div className="col-md-6 col-lg-3">
              <div
                className="single-feature-area text-center wow fadeInUp"
                data-wow-delay="800ms"
                style={{
                  visibility: "visible",
                  animationDelay: "400ms",
                  animationName: "fadeInUp",
                }}
              >
                {/* <!-- icon area --> */}
                <div className="icon-area">
                  <i className="fa fa-cog" aria-hidden="true"></i>
                  <span className="dots">
                    <i className="dot dot1"></i>
                    <i className="dot dot2"></i>
                    <i className="dot dot3"></i>
                  </span>
                </div>
                <h5>Privacy</h5>
                <p>
                  XR Token prioritizes user privacy, offering careful and
                  confidential transactions.
                </p>
              </div>
            </div>
            {/* <!-- Single Feature Area --> */}
            <div className="col-md-6 col-lg-3">
              <div
                className="single-feature-area text-center wow fadeInUp"
                data-wow-delay="800ms"
                style={{
                  visibility: "visible",
                  animationDelay: "400ms",
                  animationName: "fadeInUp",
                }}
              >
                {/* <!-- icon area --> */}
                <div className="icon-area">
                  <i className="fa fa-share-alt" aria-hidden="true"></i>
                  <span className="dots">
                    <i className="dot dot1"></i>
                    <i className="dot dot2"></i>
                    <i className="dot dot3"></i>
                  </span>
                </div>
                <h5>Ownership And Control</h5>
                <p>
                  Users have complete autonomy over their cryptocurrency
                  holdings, reducing reliance on intermediaries.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* tokenomics secion  */}
    </div>
  );
}
