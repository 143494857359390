import React from "react";
import logo from "../Assets/logo.png";
import "./Header.css";

export default function Header() {
  return (
    <div className="main_header d-none d-md-block">
      <nav className="navbar navbar-expand-lg fixed-top custom_nav_menu sticky">
        <div className="container">
          {/* <!-- LOGO --> */}
          <a className="navbar-brand logo" href="#">
            <img src={logo} alt="" className="img-fluid logo-light" />
            <img src={logo} alt="" className="img-fluid logo-dark" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="ti-menu"></i>
          </button>
          {/* <div>
          <button type="submit" class="button">Pre-Sale</button>
          </div> */}
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <ul className="navbar-nav">
              <li className="nav-item">
                <a href="#home" className="nav-link">
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a href="#about" className="nav-link">
                  About
                </a>
              </li>
              <li className="nav-item">
                <a href="#feature" className="nav-link">
                Benefits
                </a>
              </li>
              <li className="nav-item">
                <a href="#roadmap" className="nav-link">
                  Roadmap
                </a>
              </li>
              <li className="nav-item">
                <a href="#faq" className="nav-link">
                  Faq
                </a>
              </li>
              <li className="nav-item">
                <a href="https://linktr.ee/xrtokenlive?utm_source=linktree_profile_share&ltsid=62493539-3901-493f-8c7d-771403c8d4a0" target="_blank" className="nav-link">
                  Link Tree
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="assets/XRT_Whitepaper.pdf"
                  target="blank"
                  className="nav-link"
                >
                  WhitePaper
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="#contact"
                  className="nav-link"
                >
                  Contact
                </a>
              </li>
              {/* <li className="nav-item d-lg-none">
                <a
                  href="https://cnx.exchange/"
                  target="_blank"
                  className="nav-link"
                >
                  Buy
                </a>
              </li> */}
              {/* <li className="nav-item d-lg-none">
                <a
                  href="https://cnx.exchange/"
                  target="_blank"
                  className="nav-link"
                >
                  Sell
                </a>
              </li> */}
            </ul>
            {/* <div className="d-lg-flex d-none">
              <ul className="nav align-self-center">
                <li className="nav-item pl-4">
                  <a
                    className="btn header-btn"
                    href="https://cnx.exchange/"
                    target="_blank"
                  >
                    Buy
                  </a>
                </li>
                <li className="nav-item pl-4">
                  <a
                    className="btn header-btn"
                    href="https://cnx.exchange/"
                    target="_blank"
                  >
                    Sell
                  </a>
                </li>
              </ul>
            </div> */}
          </div>
        </div>
      </nav>
    </div>
  );
}
