import React from 'react'
import "./Talk.css"
export default function Talk() {
  return (
    <div>
    <section className="contact-our-area section-padding-100-50 pt_50 clearfix" id="contact">
    <div className="container">
        <div className="row">
            <div className="col-md-12 col-lg-12" align="center">
                <div className="section-heading wow fadeInUp" data-wow-delay="400ms" 
                  style={{visibility: "visible", animationDelay: "400ms", animationName: "fadeInUp"}}>
                    <h4>Let's Talk</h4>
                    <p>Aliquid qui tempore aspernatur earum, Qui dolores molestias minus esse cumque deleniti impedit quo nostrum omnis et delectus.</p>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-12">
                {/* <!-- Contact Form --> */}
                <div className="contact_from_area mb-50 wow fadeInUp" data-wow-delay="800ms" style={{visibility: "visible", animationDelay: "800ms", animationName: "fadeInUp"}}>
                    <div className="contact_form">
                        <form action="" method="post" id="main_contact_form">
                            <div className="contact_input_area">
                                <div id="success_fail_info"></div>
                                <div className="row">
                                    {/* <!-- Form Group --> */}
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input type="text" className="form-control input_contact_us mb-30" name="name" id="name" placeholder="First Name" required=""/>
                                        </div>
                                    </div>
                                    {/* <!-- Form Group --> */}
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input type="text" className="form-control input_contact_us mb-30" name="name" id="name-2" placeholder="Last Name" required=""/>
                                        </div>
                                    </div>
                                    {/* <!-- Form Group --> */}
                                    <div className="col-12">
                                        <div className="form-group">
                                            <input type="email" className="form-control input_contact_us mb-30" name="email" id="email" placeholder="E-mail" required=""/>
                                        </div>
                                    </div>
                                    {/* <!-- Form Group --> */}
                                    <div className="col-12">
                                        <div className="form-group">
                                            <textarea name="message" className="form-control input_contact_us text-area mb-30" id="message" cols="30" rows="6" placeholder="Your Message *" required=""></textarea>
                                        </div>
                                    </div>
                                    {/* <!-- Button --> */}
                                    <div className="col-12 text-center">
                                        <button type="submit" className="btn  send_msg_btn boxed-btn btn-block rounded">Send Message</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
    </div>
  )
}
