import logo from './logo.svg';
import './App.css';
import Header from './Components/Header/Header';
import Landing_page from './Components/Landing_page/Landing_page';
import Wellcome_page from './Components/Wellcome_page/Wellcome_page';
import Tokenomics from './Components/Tokenomics/Tokenomics';
import ForwardPage from './Components/ForwardPage/ForwardPage';
import About_us from './Components/About_us/About_us';
import Talk from './Components/Talk/Talk';
import Wallets from './Components/Wallets/Wallets';
import Footer from './Components/Footer/Footer';
import Mobile_menu from './Components/Mobile_menu/Mobile_menu';
import Faq from "./Components/Faq/Faq"
import Road_Map from './Components/Road_map/Road_Map';

function App() {
  return (
    <div className="App">
    <Header/>
    <Mobile_menu/>
    <Landing_page/>
    <Wellcome_page/>
    <ForwardPage/>
    <Tokenomics/>
    <Road_Map/>
    <About_us/>
    <Faq/>
    <Talk/>
    {/* <Wallets/> */}
    <Footer/>
    </div>
  );
}

export default App;
