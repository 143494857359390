import React from "react";
import three from "../Assets/3.png";
import banner from "../Assets/banner.png";
import "./Landing_page.css";

export default function Landing_page() {
  return (
    <div className="main_class_landing">
      <div className="welcome-area theme-bg" id="home">
        <div className="container h-100">
          <div className="row h-89 align-items-center">
            <div className="col-md-7">
              <div className="welcome-content mt-sm-100">
                <h3 className="wow fadeInUp" data-wow-delay="0.4s">
                Your Path to Financial Empowerment.
                </h3>
                <p className="wow fadeInUp" data-wow-delay="0.6s">
                  "Embrace the Vibrant Future of “Unlock New Opportunities With
                  XR Token And Embrace Financial Growth.”
                </p>
                {/* <div className="d-flex justify-content-center justify-content-md-start">
                  <div className="welcome-btn">
                    <a className="welcome-btn mr-2 mb-2 colo" href="" target="">
                      Buy
                    </a>
                  </div>
                  &nbsp;
                  <div className="welcome-btn1">
                    <a className="colo mr-2 mb-2" href="" target="">
                      Sell
                    </a>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="col-md-5">
              <div className="welcome-thumb">
                <img src={banner} alt="" />
                {/* <!-- <img src="assets/img/mobile.png" alt=""> --> */}
              </div>
            </div>
          </div>
        </div>
        <div className="welcome-patter">
          {/* <!-- <img src="assets/img/bg-img/bg-patter-2.png" alt=""> --> */}
          <img src={three} alt="" />
        </div>
      </div>
    </div>
  );
}
