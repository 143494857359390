import React from "react";
import one from "../Assets/pattern-img/1.png";
import abt from "../Assets/aboutus.png";

export default function About_us() {
  return (
    <div>
      <div className="about-us-area section-padding-100-50 pt_50" id="about">
        <div className="container">
          <div className="row">
            <div className="col-md-9 col-lg-7">
              <div
                className="section-heading wow fadeInUp"
                data-wow-delay="400ms"
                style={{
                  visibility: "visible",
                  animationDelay: "400ms",
                  animationName: "fadeInUp",
                }}
              >
                {/* <h6>About Us</h6> */}
                <h4>About Us</h4>
                <p>
                  XR Token is a unique and innovative cryptocurrency designed to
                  change the world of digital money. <br />
                  We envision a future where individuals are not only passive
                  users but active participants in shaping their financial
                  journey. As we embark on this revolutionary journey, we invite
                  individuals from all walks of life to join us in this
                  transformational movement.
                </p>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-md-5">
              <div
                className="about-thumb-video mb-50 wow fadeInUp"
                data-wow-delay="800ms"
                style={{
                  visibility: "visible",
                  animationDelay: "800ms",
                  animationName: "fadeInUp",
                }}
              >
                <div className="about-thumb">
                  <img src={abt} alt="" />
                </div>
              </div>
            </div>
            <div
              className="col-md-7 wow fadeInUp"
              data-wow-delay="800ms"
              style={{
                visibility: "visible",
                animationDelay: "800ms",
                animationName: "fadeInUp",
              }}
            >
              <div className="row">
                {/* <!-- Single Card --> */}
                <div className="col-md-6">
                  <div className="about-single-card">
                    <h4>Trusted and Supportive Community</h4>
                    <p>
                      With XR Token,Join a thriving community of traders,
                      investors, and enthusiasts who share a common goal of
                      reshaping the future of finance.
                    </p>
                    {/* <a href="#">
                      Read more <i className="ti-angle-double-right"></i>
                    </a> */}
                    <div className="about-pattern">
                      <img src={one} alt="" />
                    </div>
                  </div>
                </div>
                {/* <!-- Single Card --> */}
                <div className="col-md-6">
                  <div className="about-single-card">
                    <h4>Innovative Ecosystem</h4>
                    <p>
                    XR token offers a diverse ecosystem encompassing blockchain technology with its unique DAO projects.{" "}
                    </p>
                    {/* <a href="#">
                      Read more <i className="ti-angle-double-right"></i>
                    </a> */}
                    <div className="about-pattern">
                      <img src={one} alt="" />
                    </div>
                  </div>
                </div>
                {/* <!-- Single Card --> */}
                <div className="col-md-6">
                  <div className="about-single-card">
                    <h4>Safe And Secure</h4>
                    <p>
                      At XR Token, the safety and security of our users'
                      financial assets and data are of importance to us.{" "}
                    </p>
                    {/* <a href="#">
                      Read more <i className="ti-angle-double-right"></i>
                    </a> */}
                    <div className="about-pattern">
                      <img src={one} alt="" />
                    </div>
                  </div>
                </div>
                {/* <!-- Single Card --> */}
                <div className="col-md-6">
                  <div className="about-single-card">
                    <h4>Connect People</h4>
                    <p>
                      We aim to foster a thriving global community by connecting
                      people through the power of decentralized finance.
                    </p>
                    {/* <a href="#">
                      Read more <i className="ti-angle-double-right"></i>
                    </a> */}
                    <div className="about-pattern">
                      <img src={one} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
