import React from 'react'
import token from "../Assets/tokenomics.png"
import featurImg from "../Assets/7.png"


export default function Tokenomics() {
  return (
    <div>

<section className="timeline subc-area section-divide" id="tokenomics">
    <div className="container">
        <div className="row">
            <div className="col-md-12">
                <div className="section-title w-90 m-l-5 text-center">
                    <h2>Tokenomics</h2>
                    
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-12">
                <div className="tokenomicsImg">
                    <img src={token}/>
                </div>
            </div>
        </div>
    </div>
    <div className="container">
        <div className="row">
            <div className="col-md-12">
                <div className="section-title w-90 m-l-5 text-center">
                    <h2>Feature of XRT</h2>
                    <p>21 million supply of XRT will be unlocked in next 1000 days</p>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-6">
                <div className="Feature_details">
                    <div className="feature_item">
                        <div>Total Supply</div>
                        <div>21 Million</div>
                    </div>
                    <div className="feature_item">
                        <div>Circulation Supply</div>
                        <div>1 Million</div>
                    </div>
                    <div className="feature_item">
                        <div>Lock Supply</div>
                        <div>20 Million</div>
                    </div>
                    <div className="feature_item">
                        <div>Lock Time</div>
                        <div>1000 Days</div>
                    </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="featureImg">
                    <img src={featurImg} alt={featurImg} />
                </div>
            </div>
        </div>
    </div>
</section>
    </div>
  )
}
