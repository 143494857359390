import React from "react";
import eight from "../Assets/08.png";

export default function ForwardPage() {
  return (
    <div>
      <div class="uniq-area section-padding-100-50">
        <div class="container">
          <div class="row align-items-center">
            <div
              class="col-md-6 mb-50 wow fadeInUp"
              data-wow-delay="400ms"
              style={{
                visibility: "visible",
                animationDelay: "400ms",
                animationName: "fadeInUp",
              }}
            >
              {/* <!-- Thumb area --> */}
              <div class="uniq-content-thumb-1">
                <img src={eight} alt="" />
              </div>
              {/* <!-- Follow us area -->
                <!--  <div class="uniq-follow-us">
                    <div class="uniq-follow text-center">
                        <h4>Follow us</h4>
                        <a href="#"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                        <a href="#"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                        <a href="#"><i class="fa fa-instagram" aria-hidden="true"></i></a>
                        <a href="#"><i class="fa fa-dribbble" aria-hidden="true"></i></a>
                    </div>s
                    </div> --> */}
            </div>
            <div
              class="col-md-6 mb-50 wow fadeInUp"
              data-wow-delay="400ms"
              style={{
                visibility: "visible",
                animationDelay: "400ms",
                animationName: "fadeInUp",
              }}
            >
              <div class="uniq-content-text">
                <div
                  class="section-heading wow fadeInUp"
                  data-wow-delay="400ms"
                  style={{
                    visibility: "visible",
                    animationDelay: "400ms",
                    animationName: "fadeInUp",
                  }}
                >
                  <h6>Forward</h6>
                  <h4>Forword</h4>
                  <p>
                    Welcome to XR Token, the pioneering cryptocurrency that
                    reshapes the future of digital money. With safe,
                    decentralized solutions and cutting-edge technology like Xtended reality and multiple DAOs on blockchain, metaverse, wallet, and gaming platform, we
                    foster innovation and explore the vast possibilities of
                    decentralized finance. Join us on this transformative
                    journey as we redefine the landscape of digital money,
                    connecting people worldwide and building a bridge to a more
                    inclusive and prosperous financial future. Embrace the
                    boundless potential of XR Token, where the future of
                    finance awaits.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
