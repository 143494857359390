import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import logo from "../Assets/logo.png";
import "./Mobile_menu.css";
import { RxCross2 } from "react-icons/rx";
import { AiOutlineMenu } from "react-icons/ai";

export default function Mobile_menu() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  return (
    <div className="main_mobile_btn d-block d-md-none">
      <Navbar
        collapseOnSelect
        expand="lg"
        className="bg-body-tertiary nav_bar_bg"
      >
        <Container>
          <Navbar.Brand className="main_logo" href="#home">
            <img src={logo} alt="" style={{width: "50%"}} />
          </Navbar.Brand>
          {/* <div>
          <button type="submit" class="button">PreSale</button>
          </div> */}
          {/* <Navbar.Toggle aria-controls="responsive-navbar-nav" /> */}
          <span onClick={() => setShow(!show)}>
            {show ? (
              <>
                <RxCross2 className="text-white cross" />{" "}
              </>
            ) : (
              <>
                <AiOutlineMenu className="text-white cross" />
              </>
            )}
          </span>
          <Navbar.Collapse
            id="responsive-navbar-nav "
            className={show ? "show" : ""}
          >
            <Nav className="me-auto bordersd ">
              <Nav.Link
                onClick={handleClose}
                className="mobile_menu_links"
                href="#home"
              >
                home
              </Nav.Link>
              <Nav.Link
                onClick={handleClose}
                className="mobile_menu_links"
                href="#about"
              >
                about
              </Nav.Link>
              <Nav.Link
                onClick={handleClose}
                className="mobile_menu_links"
                href="#feature"
              >
                Benefits
              </Nav.Link>
              <Nav.Link
                onClick={handleClose}
                className="mobile_menu_links"
                eventKey={2}
                href="#roadmap"
              >
                roadmap
              </Nav.Link>
              <Nav.Link
                onClick={handleClose}
                className="mobile_menu_links"
                href="#faq"
              >
                faq
              </Nav.Link>
              <Nav.Link
                onClick={handleClose}
                className="mobile_menu_links"
                href="https://linktr.ee/xrtokenlive?utm_source=linktree_profile_share&ltsid=62493539-3901-493f-8c7d-771403c8d4a0"
                target="_blank"
              >
                Link Tree{" "}
              </Nav.Link>
              <Nav.Link
                onClick={handleClose}
                className="mobile_menu_links"
                href="assets/XRT_Whitepaper.pdf"
                target="_blank"
              >
                WhitePaper
              </Nav.Link>
              <Nav.Link
                onClick={handleClose}
                className="mobile_menu_links"
                href="#contact"
              >
                Contact
              </Nav.Link>
              {/* <Nav.Link
                onClick={handleClose}
                className="mobile_menu_links"
                href="https://cnx.exchange/"
              >
                Buy
              </Nav.Link>
              <Nav.Link
                onClick={handleClose}
                className="mobile_menu_links"
                href="https://cnx.exchange/"
              >
                Sell
              </Nav.Link> */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}
