import React from "react";
import logo from "../Assets/logo.png";

export default function Footer() {
  return (
    <div className="Footer_Main">
      <div className="footer-contact-area bg-img pb_20">
        {/* <!-- Copy Right Area --> */}
        <div className="copy-right-area">
          <div className="container">
            <div className="row">
              {/* <!-- Copy Right Menu --> */}
              <div className="col-md-3">
                <div className="footer-logo">
                  <a href="#">
                    <img src={logo} alt="" />
                  </a>
                </div>
                {/* <div className="copy-right-menu">
                  <div className="footer-contact-icon mt-30">
                      <a href="#"><i className="fa fa-facebook-f"></i></a>
                      <a href="#"><i className="fa fa-twitter"></i></a>
                      <a href="#"><i className="fa fa-linkedin"></i></a>
                      <a href="#"><i className="fa fa-skype"></i></a>
                  </div>
                </div> */}
              </div>
              <div className="col-md-7">
                <div className="footer-wrapper">
                  <h4 className="footer-text">Our company</h4>
                  <ul className="footer-menu">
                      <li><a href="#home"><i className="fa fa-caret-right"></i>Home</a></li>
                      <li><a href="#about"><i className="fa fa-caret-right"></i>About</a></li>
                      <li><a href="#feature"><i className="fa fa-caret-right"></i>Benefits</a></li>
                      <li><a href="#faq"><i className="fa fa-caret-right"></i>Faq</a></li>
                      <li><a href="#roadmap"><i className="fa fa-caret-right"></i>Roadmap</a></li>
                      <li><a href="https://linktr.ee/xrtokenlive?utm_source=linktree_profile_share&ltsid=62493539-3901-493f-8c7d-771403c8d4a0" target="_blank"><i className="fa fa-caret-right"></i>Link Tree</a></li>
                      <li><a href="assets/XRT_Whitepaper.pdf" target="blank"><i className="fa fa-caret-right"></i>WhitePaper</a></li>
                      <li><a  href="#contact"><i className="fa fa-caret-right"></i>Contact</a></li>
                  </ul>
                </div>
              </div>
              <div className="col-md-2">
              <div className="copy-right-menu footer-wrapper">
              <h4 className="footer-text">Social Media</h4>
                  <div className="footer-contact-icon mt-30">
                      <a href="http://twitter.com/XRTokenlive" target="_blank"><i className="fa fa-twitter"></i></a>
                      <a href="http://instagram.com/XRTokenlive/" target="_blank"><i className="fa fa-instagram"></i></a>
                      <a href="https://t.me/Xrtokenlive" target="_blank"><i className="fa fa-paper-plane"></i></a>
                      {/* <a href="#"><i className="fa fa-skype"></i></a> */}
                  </div>
                </div>
              </div>
              {/* <!-- Copy Right Content --> */}
              <div className="col-md-12 text-center mt-sm-cu-30">
                <div className="copy-right-content">
                  <p>Copyright 2023 © XRtoken | All Rights Reserved</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
